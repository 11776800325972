
import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index/index.vue";
import Home from "../views/Home.vue";
import Login from "../views/login/login.vue";
import Setting from "../views/setting/setting.vue";
import Success from "../views/success/success.vue";
import History from "../views/history/history.vue";
import Relation from "../views/relation/relation.vue";
import Help from "../views/ask/ask.vue";
import Draft from "@/views/draft.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    // 首页
    {
        path: "/index",
        name: "Index",
        component: Index,
    },
	{
	    path: "/",
	    name: "Home",
	    component: Home,
	},
    // 登录
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
	// 忘记密码
	{
	    path: "/help",
	    name: "Help",
	    component: Help,
	},
    // 忘记密码
    {
        path: "/setting",
        name: "Setting",
        component: Setting,
    },
    {
        path: "/success",
        name: "Success",
        component: Success,
    },
	// 测算历史
	{
	    path: "/history",
	    name: "History",
	    component: History,
	},
    {
        path: "/drafts",
        name: "Drafts",
        component: Draft,
    },
	{
	    path: "/relation",
	    name: "Relation",
	    component: Relation,
	},
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => {
        history.pushState(null, null, document.URL)
    }
});
export default router;