<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {
		created(){
	
		}
	}
</script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
		list-style: none;
		text-decoration: none;
		/* 这两个在技术上是一样的, 为了兼容了浏览器两个都加上 */
		overflow-wrap: break-word;
		word-wrap: break-word;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* 如果浏览器支持的话增加一个连接符(Blink不支持) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
	
	/* 滚动条整体部分,必须要设置 */
	::-webkit-scrollbar {
	  width: 8px;
	  height: 8px;
	  background-color: #f5f6fa;
	}
	
	/* 滚动条的轨道 */
	::-webkit-scrollbar-track {
	  width: 8px;
	  height: 8px;
	}
	
	/* 滚动条的滑块按钮 */
	::-webkit-scrollbar-thumb {
	  width: 6px;
	  height: 6px;
	  border-radius: 4px;
	  background-color: #b6bece;
	  cursor: pointer;
	}
	
	/* 滚动条的上下两端的按钮 */
	::-webkit-scrollbar-button {
	  height: 6px;
	  width: 0;
	}
	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-col {
		display: flex;
		flex-direction: column;
	}

	.flex-1 {
		flex: 1;
	}

	.lease-btn {
		width: 40px;
		height: 40px;
		cursor: pointer;
		background: #000000;
		color: #ffffff;
	}

	.el-popper[x-placement^="bottom"] {
		border: 1px solid #797979;
		border-radius: 0;

		.desc {
			margin-bottom: 20px;
		}

		.sign-out {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			width: 73px;
			height: 30px;
			border-radius: 3px;
			background-color: #de7c2b;
			font-size: 13px;
			margin-top: 20px;
		}

		.path-list {
			.active {
				color: #de7c2b;
			}

			li {
				cursor: pointer;
				transition: 0.3s;

				p {
					height: 40px;
					display: flex;
					align-items: center;
					// justify-content: center;
				}

				&:hover {
					color: #de7c2b;
				}
			}
		}
	}

	.el-dropdown-menu {
		border: none !important;
		box-shadow: none !important;
	}

	.popper__arrow {
		display: none !important;
	}

	#nav {
		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
	.el-input__inner{
		padding: 0 5px !important;
	}
	.shepherd-button:not(:disabled):hover{
		background: #de7c2b !important;
	}
	.shepherd-element{
		border: 0px #fff !important;
		margin-top: 10px;
	}
	.el-button-left1{
		margin-right: 180px !important;
	}
	.el-button-left{
		margin-right: 60px !important;
	}
	.example-step-extra-class1{
		margin-top: 15px !important;
	}
	.example-step-extra-class2{
		margin-top: -15px !important;
	}
	.example-step-extra-class3{
		margin-left: -15px !important;
	}
	.example-step-extra-class4{
		margin-left: 15px !important;
	}
	
	input::-webkit-inner-spin-button{
		-webkit-appearance: none!important;
	}
	input::-webkit-outer-spin-button{
		-webkit-appearance: none!important;
	}
	input[type="number"]{
		-moz-appearance: textfield;
	}
	.el-form-item{
		margin-left: 4px !important;
	}
	
	.el-input__suffix-inner{
		background-color: #fff!important;
	}
	.is-disabled .el-input__suffix-inner{
		display: none;
	}
	.el-radio{
		margin-right: 10px!important;
	}
	.el-input-group__append{
		padding: 0px 4px !important;
	}
	.italic-placeholder input::placeholder {
		font-style: italic;
	}
	.text-color{
		color: #DE7C2B;
		font-weight: bold;
	}
</style>