<template>
  <div class="body">
    <Nav now="1"></Nav>
    <!-- 记录 -->
    <div class="history">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="title">Drafts</div>
        <div style="flex: 1;"></div>
        <el-button type="primary" @click="back">{{ QWER('返回') }}</el-button>
        <el-button type="primary" @click="submit">{{ QWER('再次测评') }}</el-button>
      </div>
      <div style="margin-top: 40px;" v-for="(item,index) in tableData">
        <div style="display: flex;flex-direction: row;">
          <div class="table-title">ID：{{ item.id }}</div>
          <div class="table-title">{{ item.created_at }}</div>

          <el-button type="primary" size="mini" @click="toShowDetail(item)" style="margin-left: 20px;">
            {{ QWER('评测详情') }}
          </el-button>
          <el-button type="primary" size="mini" @click="deleteItem(item)" style="margin-left: 20px;">
            Delete
          </el-button>
        </div>
        <div style="margin-top: 25px;">
          <div class="table-h1-bg table-flex">
            <div class="table-h1">{{ QWER('贷款用途') }}</div>
            <div class="table-h1">Total Target Loan Amount</div>

          </div>
          <div class="table-flex" v-for="(item2,index2) in item.purpose_list">
            <div class="table-text">{{ item2.loan_purpose }}</div>
            <div class="table-text">{{ item2.target_total }}</div>
          </div>
        </div>
      </div>
      <div v-if="total>tableData.length" style="width: 100%;text-align: center;">{{ QWER('加载中') }}...</div>
      <div v-if="total==tableData.length" style="width: 100%;text-align: center;">{{ QWER('已全部加载') }}</div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav/Nav.vue";
import {getDraftsList, deleteDraft} from "@/api/loans/loans";
import {getUserInfo} from "@/api/login/login"

export default {
  data() {
    return {
      tableData: [],
      total: 0,
      nowPages: 1,
      pageSize: 10,
      info: {},
      infoList: [],
      showDetailDialog: false,
      detail: {},
      show: false,
      loading: false,
      params:{
        user_id:"",
        key:""
      }

    };
  },
  created() {
    this.init();
    if(this.$route.query.user_id){
      this.params.user_id = this.$route.query.user_id
    }
    if(this.$route.query.key){
      this.params.key = this.$route.query.key
    }
  },
  methods: {
    QWER(message, obj1) {
      let temp = this.$t(message)
      if (obj1) {
        temp = temp.replace('xxxxxx', obj1)
      }
      return temp
    },
    showRemark(item){
      if(item.remarks==''){
        item.remarks = "No Addition Note"
      }
      this.$alert(item.remarks, this.QWER('提示'), {
        confirmButtonText: this.QWER('确定'),
        dangerouslyUseHTMLString: true,
        callback: action => { }
      });
    },
    deleteItem(item){
      this.$confirm("Confirm to delete?", this.QWER('提示'), {
        confirmButtonText: this.QWER('确定'),
        cancelButtonText: this.QWER('取消'),
        type: 'warning'
      }).then(() => {
        deleteDraft({id:item.id}).then(res=>{
          this.init()
        })
      }).catch(() => {})
    },
    async init() {
      if (this.nowPages == 1) {

        this.loading = true;
      }

      const list = await getDraftsList(this.params);

      this.tableData = list.data ? list.data : [];
      this.loading = false;
    },
    async showDetail(item) {
      this.detail = item.main_lists
      this.showDetailDialog = true
    },
    back() {
      this.$router.push('/index')
    },
    submit() {
      this.$router.push('/')
    },
    toShowDetail(item) {
      localStorage.setItem("mainData", item.sub_data)
      window.open('#/index?drafts=true&drafts_id=' + item.id, '_blank');
    }

  },
  components: {
    Nav,
  },
};
</script>

<style lang="scss" scoped>
@import "@/baseScss/baseScss.scss";

.body {
  display: flex;
  height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  background: #F5F5F5;
  min-width: 1650px;
  .history {
    flex: 1;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 0px rgba(243, 243, 243, 0.5);
    border-radius: 10px;
    margin: 20px;
  }

  .title {
    font-weight: bold;
    font-size: 36px;
    color: #000000;
  }

  .table-title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    margin-right: 40px;
  }

  .status_bg1 {
    font-weight: 400;
    font-size: 18px;
    color: #DE7C2B;
    line-height: 22px;
  }

  .status_bg2 {
    font-weight: 400;
    font-size: 18px;
    color: #43C93E;
    line-height: 22px;
  }

  .table-h1 {
    font-weight: bold;
    font-size: 14px;
    flex: 1;
    color: #333333;
    height: 48px;
    line-height: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .table-h1-1 {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    flex: 1;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-h1-bg {
    background: #F5F8FA;
    border-radius: 8px;
  }

  .table-flex {
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  .table-text1 {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #666666;
    line-height: 61px;
  }

  .table-text {
    font-weight: 400;
    font-size: 14px;
    flex: 1;
    color: #666666;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
