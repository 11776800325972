<template>
  <el-input
    v-model="formattedValue"
    @input="handleInput"
    @blur="handleBlur"
	:disabled="disabled"
    :placeholder="placeholder?placeholder:'Enter'"
  >
  <template slot="append" v-if="append">
	   {{append}}
  </template>

	
  <!-- 使用具名插槽，接收外部传入的append插槽内容 -->
    
  </el-input>
</template>

<script>
export default {
  name: 'MoneyInput',
  props: {
    value: {
      type:  [String, Number],
      default: ''
    },
	append:{
		type:  [String, Number],
		default: ''
	},
	disabled: {
	      type: Boolean,
	      default: false
	    },
		// 新增接收placeholder属性的props定义
		    placeholder: {
		      type: String,
		      default: ''
		    }
  },
  data() {
    return {
      formattedValue: this.value
    };
  },
  // 千分位格式化函数
  methods: {
    formatMoney(value) {
      if (!value) return '';
      value = value.toString().replace(/,/g, ''); // 去除已有千分位
      const parts = value.split('.');
      parts[0] = Number(parts[0])+''
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 添加千分位
      return parts.join('.');
    },
    // 数值校验函数
    validateMoney(value) {
		 if (!value) return '';
      const regex = /^\d{1,30}(\.\d{1,2})?$/;
      return regex.test(value.toString().replace(/,/g, ''));
    },
	
    // 处理输入事件
    handleInput(event) {
      if(!event){
        this.formattedValue = ""
        return
      }
      const value = event;
      if (this.validateMoney(value)) {
		    this.$emit('input',value.toString().replace(/,/g, ''));
        this.formattedValue = this.formatMoney(value);
        
      } else {
        this.formattedValue = this.value;
      }
    },
    // 处理失焦事件
    handleBlur() {
      if (!this.validateMoney(this.formattedValue)) {
        this.formattedValue = '';
        this.$emit('input', '');
      }
    }
  },
  watch: {
    'value':{
		handler(newValue, oldVal) {
			this.formattedValue = this.formatMoney(newValue);
		},
		deep: true,
		immediate: true // 立即执行一次
	}
  }
};
</script>

<style>
</style>