<template>
	<div class="nav">
		<div class="logo">
			<img @click="confirmBack()"
				src="logo.png"
				alt=""/>
		</div>
		<div style="flex: 1;color: white" @dblclick="changeLang('zh')">.</div>
		<ul class="nav-list">
			<li v-for="(item, index) in navList" :key="item.index" v-if="item.index==7&&userInfo.id" class="nav-item" @click="jumpNav(item)"
				@mouseenter="onHover(index)" @mouseleave="hover = -1">

				<el-dropdown @command="jumpNav" :show-timeout="0" :hide-timeout="100" placement="bottom">
					<p :class="current == item.index ? 'nav-name active' : 'nav-name'">
						{{ item.name }}
					</p>
					<el-dropdown-menu slot="dropdown" v-show="item.option">
						<el-dropdown-item :command="ele.url" v-for="ele in item.option" :key="ele.name">
							<p style="font-size: 13px">{{ ele.name }}</p>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</li>

			<li class="nav-item" v-if="user">
				<el-dropdown  @command="jumpNav2" :show-timeout="0" :hide-timeout="100" placement="bottom">
					<p  class=" nav-name" style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
						<i class="el-icon-user"></i>
						<span>{{ user?.email }}</span>
						<i class="el-icon-arrow-down"></i>
					</p>
					<el-dropdown-menu slot="dropdown" >
						<el-dropdown-item :command="1" key="History">
							<p style="font-size: 13px">History</p>
						</el-dropdown-item>
            <el-dropdown-item :command="2" key="Drafts">
              <p style="font-size: 13px">Drafts</p>
            </el-dropdown-item>
						<el-dropdown-item :command="3">
							<p style="font-size: 13px">Sign out</p>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</li>
			<li  class="nav-item" @click="jumpHelp()">
				<el-badge value="New" class="item" :hidden="!hasNewMsg">
				  <p class=" nav-name" style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
				  	HELP
				  </p>
				</el-badge>
				
			</li>
		</ul>
	</div>
</template>

<script>
	import {
		getUserInfo,
		removeToken,
		removeUserInfo,
		getToken
	} from "@/utils/myAuth";
	import {
		smsCode
	} from "@/api/login/login.js";
	import {getIsNewMsg} from "@/api/loans/loans.js";
	export default {
		data() {
			return {
				// 导航列表
				navList: [{
						name: "HOME",
						index: 1,
						url: "https://reginsun.com/",
					},
					{
						name: "ABOUT US",
						index: 2,
						url: "https://reginsun.com/reginsun-group",
						option: [{
								name: "REGINSUN GROUP",
								url: "https://reginsun.com/reginsun-group",
							},
							{
								name: "WHAT WE DO",
								url: "https://reginsun.com/what-we-do",
							},
							{
								name: "OUR TEAM",
								url: "https://reginsun.com/our-team",
							},
							{
								name: "QUALIFICATIONS",
								url: "https://reginsun.com/our-qualifications",
							},
						],
					},
					{
						name: "CREDIT ASSISTANCE",
						index: 3,
						url: "https://reginsun.com/business-commercial",
						option: [{
								name: "COMMERCIAL LOANS",
								url: "https://reginsun.com/business-commercial",
							},
							{
								name: "HOME LOANS",
								url: "https://reginsun.com/home-loans",
							},
							{
								name: "DEVELOPMENT FINANCE",
								url: "https://reginsun.com/development",
							},
							{
								name: "EQUIPMENT FINANCE",
								url: "https://reginsun.com/equipment-finance",
							},
						],
					},
					{
						name: "FUNDS MANAGEMENT",
						index: 4,
						url: "https://reginsun.com/trustee-services",
						option: [{
							name: "TRUSTEE SERVICES",
							url: "https://reginsun.com/trustee-services",
						}, ],
					},
					{
						name: "SUCCESS STORIES",
						index: 5,
						url: "https://reginsun.com/success-stories",
					},
					{
						name: "BLOG",
						index: 6,
						url: "https://reginsun.com/blog",
					},
					{
						name: "Login",
						index: 7,
						url: "/login",
					}
				],

				userInfo: {
					id: "",
				},
				user: {},
				current: 1,
				hover: -1,
				pathList: [
				  {
				    name: "History",
				    url: "/history",
				  },
          {
            name: "Drafts",
            url: "/drafts",
          }
				],
				count:0,
				hasNewMsg:false,
				timeInterval:null
			};
		},
		created() {
			this.init();
			this.getNewMsg()
			window.addEventListener('focus', this.init);
		},
		beforeDestroy() {
			if(this.timeInterval!=null){
				clearTimeout(this.timeInterval)
			}
			window.removeEventListener('focus', this.init);
		},
		methods: {
			getNewMsg(){
				const token = getToken()
				if (token) {
					getIsNewMsg().then(res=>{
						if(res.data){
							this.hasNewMsg = true
						}else{
              this.hasNewMsg = false
            }
            this.$emit("new",this.hasNewMsg)
					})
				}
				this.timeInterval = setTimeout(()=>{
					this.getNewMsg()
				},30*1000)
			},
			confirmBack(){
				if(this.$route.path=='/login'||this.$route.path=='/'){
					return
				}
				this.$confirm(this.QWER('All data will be lost if ‘Confirm’ is clicked! '), this.QWER('提示'), {
				          confirmButtonText: this.QWER('确定'),
				          cancelButtonText: this.QWER('取消'),
				          type: 'warning'
				        }).then(() => {
				          this.$jump('/')
				        }).catch(() => {
				                   
				        });
				
			},
			QWER(message) {
				let temp =  this.$t(message)
			   return temp
			},
			changeLang(name){
				this.count++
				if(this.count>=2){
					this.count=0
					if(this.$i18n.locale=='en'){
						name = 'zh'
					}else{
						name = 'en'
					}
					this.$i18n.locale = name
					localStorage.setItem('lang',name)
					
				}
				
			},
			async init() {
				const user = JSON.parse(getUserInfo());
				this.userInfo.id = !user ? 1 : "";
				this.user = user;
				this.current = this.index;
			},
			jump(url) {
				if (url == this.$route.path) return;
				this.$jump(url+'?path='+this.$route.path);
			},
			jumpNav(command) {
				if (command.index == 7) {
					this.jump(command.url);
				} else{
					window.open(command.url);
				}

			},
			jumpHelp(){
				let routeData = this.$router.resolve({
				    path: '/help',
				});
				const hasHelp = window.open(routeData.href,'help')
				if(hasHelp){
					hasHelp.focus()
				}else{
					window.open(routeData.href, 'help');
				}
				this.hasNewMsg = false
				
			},
			jumpNav2(coommand){
        console.log(coommand)
				if(coommand==1){
					this.$router.push("/history");
				}
        if(coommand==2){
          this.$router.push("/drafts");
        }
				if(coommand==3){
					this.out()
				}
			},
			onHover(index) {
				this.hover = index;
			},
			async out() {
				let loading = this.$loading()
				await removeToken();
				await removeUserInfo();
				this.userInfo.id = "";
				setTimeout(() => {
					this.$router.push("/login");
					loading.close()
				}, 300)
			},
		},
		props: ["now"],
	};
</script>

<style lang="scss" scoped>
	@import "@/baseScss/baseScss.scss";
.el-dropdown-menu {
  min-width: 100px;
}
	.nav {
		@font-face {
			font-family: "proxima nova";
		}
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 85px !important;
		flex-shrink: 0;
		background-color: #fff;
		box-sizing: border-box;
		padding: 0 20px;
		z-index: 100;
		min-width: 1650px;
		background-color: #fff;
		font-family: "proxima nova";
		box-shadow: 0px 0px 10px 0px #D8D8D8;

		.logo {
			cursor: pointer;
			height: 35px;
			width: 165px;
			margin-top: 25px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.nav-list {
			display: flex;
			align-items: center;
			height: 100%;

			.nav-item {
				display: flex;
				align-items: center;
				padding: 0 16px;
				height: 39px;
				position: relative;
				cursor: pointer;

				.nav-name {
					@include center;
					// display: flex;
					transition: color 0.1s 0s ease-in-out;

					font-weight: 400;
					font-style: normal;
					font-size: 13px;
					height: 40px;
					letter-spacing: 1px;
					text-transform: uppercase;
					text-decoration: none;
					white-space: nowrap;
					line-height: 1em;
					color: rgba(97, 97, 97, 0.9);
				}

				.nav-dropdown {
					position: absolute;
					top: 80px;
					background-color: #fff;
					left: 0;
					font-family: "proxima nova";
					font-weight: 400;
					font-style: normal;
					font-size: 13px;
					letter-spacing: 1px;
					text-transform: uppercase;
					text-decoration: none;
					line-height: 1em;
					color: rgba(97, 97, 97, 0.9);
				}

				.el-dropdown {
					@include center;
					height: 40px;

					span {
						@include center;
						height: 40px;
					}
				}

				.active {
					color: $color;
				}
			}

			.nav-btn {
				@include center;
				@include mask;
				cursor: pointer;
				width: 132px;
				height: 39px;
				border-radius: 5px;
				font-size: 13px;
				color: #fff;
				margin-left: 13px;
				background-color: #f58954;
				font-family: "proxima nova";
				font-weight: 400;
				font-style: normal;
				letter-spacing: 1.5px;
				text-transform: uppercase;
				text-decoration: none;
			}

			.user {
				cursor: pointer;

				span {
					margin: 0 5px;
				}
			}
		}
	}

	.mask {
		@include center;
		background-color: rgba(31, 37, 38, 0.35);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;

		.nav {
			@include between;
			height: 85px;
			position: sticky;
			top: 0;
			background-color: #fff;
			box-sizing: border-box;
			padding: 0 20px;
			z-index: 100;

			.logo {
				width: 200px;
				height: 45px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nav-list {
				display: flex;
				align-items: center;

				.nav-item {
					display: flex;
					align-items: center;
					padding: 0 13px;
					cursor: pointer;

					.el-dropdown {
						@include center;
					}

					.nav-name {
						@include center;
						font-size: 13px;
						color: rgba(97, 97, 97, 0.9);
						height: 30px;

						&:hover {
							color: $color;
						}
					}

					.active {
						color: $color;
					}
				}

				.nav-btn {
					@include center;
					@include mask;
					cursor: pointer;
					width: 132px;
					height: 39px;
					border-radius: 5px;
					font-size: 13px;
					color: #fff;
					margin-left: 10px;
					background-color: #de7c2b;
				}
			}
		}
	}

	::v-deep .popper__arrow {
		display: none !important;
	}

	::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
		background-color: #fff !important;
		color: $color;
	}
</style>