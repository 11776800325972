/*
 * @Author: LegerVeilchen LegerVeilchen@foxmail.com
 * @Date: 2022-06-06 16:36:35
 * @LastEditors: LegerVeilchen LegerVeilchen@foxmail.com
 * @LastEditTime: 2022-06-08 18:46:49
 * @FilePath: \loan-web\src\api\login\login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";
// 登录
const userLogin = (data) => {
  return request({
    url: "auth/login",
    method: "post",
    data,
  });
};

// 获取注册验证码
const smsCode = (data) => {
  return request({
    url: `auth/sendEmailLogin`,
    method: "post",
    data,
  });
};

const getUserInfo = (data) => {
  return request({
    url: `auth/getUserInfo`,
    method: "post",
    data,
  });
};


export {
  userLogin,
  smsCode,
  getUserInfo
};